@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    
        .flex-right {
            @apply flex items-center justify-end;
        }
    
        .flex-left {
            @apply flex items-center justify-start;
        }
    
        .center {
            @apply flex items-center justify-center;
        }
    
        .items-between {
            @apply flex items-center justify-between;
        }
    
    .highcharts-background{
        fill: transparent;
    }

    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    /* Reach Components */
    [data-reach-combobox-option][data-selected],
    [data-reach-combobox-option][data-highlighted],
    [data-reach-combobox-option]:hover{
      background: #ccc;
      color: black;
    }
    
    /* Number inputs fix */
    input[type="number"].appearance-none::-webkit-outer-spin-button,
    input[type="number"].appearance-none::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"].appearance-none {
        -moz-appearance: textfield;
    }
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

.skeleton-loader {
    position: relative;
    overflow: hidden;
    background-color: #E7EDF0;
}

.skeleton-loader::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0,
            rgba(255, 255, 255, 0.5) 25%,
            rgba(255, 255, 255, 0.8) 75%,
            rgba(255, 255, 255, 0)
    );
    animation: shimmer 3s infinite;
    content: "";
}
